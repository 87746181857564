export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const OVERVIEW_PATH = '/overview';
export const ALL_INTERCEPTIONS_PATH = '/all-interceptions';
export const RADIO_NETWORKS_PATH_BASE = '/radio-networks';
export const RADIO_NETWORKS_PATH = `${RADIO_NETWORKS_PATH_BASE}/:radioNetworkId?/:activeTab?`;
export const FACTIONS_PATH_BASE = '/factions';
export const FACTIONS_PATH = `${FACTIONS_PATH_BASE}/:factionId?`;
export const CATEGORIES_PATH_BASE = '/categories';
export const CATEGORIES_PATH = `${CATEGORIES_PATH_BASE}/:categoryId?`;
export const ACCESS_GROUPS_PATH_BASE = '/access-groups';
export const ACCESS_GROUPS_PATH = `${ACCESS_GROUPS_PATH_BASE}/:activeTab?/:accessGroupId?`;
export const UNITS_PATH_BASE = '/units';
export const UNITS_PATH = `${UNITS_PATH_BASE}/:unitId?/:activeTab?`;
export const PHONE_CONFIRMATION_PATH = 'phone-confirmation';
export const AUTHENTICATOR_SETUP_PATH = 'authenticator-setup';
export const GOOGLE_AUTH_CONFIRMATION_PATH = 'google-auth-confirmation';
export const SIGNUP_PATH = '/signup';
export const USER_INACTIVE_PATH = 'user-inactive';
export const RECOVERY_PASSWORD_PATH = 'recovery-password';
export const CHANGE_PASSWORD_PATH = 'change-password';
export const SOURCES_PATH_BASE = '/sources';
export const SOURCES_PATH = `${SOURCES_PATH_BASE}/:sourceId?/:activeTab?/:tabEntityId?`;
