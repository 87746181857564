import { FormInstance } from 'antd';
import isObject from 'lodash/isObject';
import { ZodError } from 'zod';
import { ValidationError } from '@/shared/api/errors';
import { WithValidationSchema, Paginated, ResponseData } from '@/shared/api/types';
import { EMPTY_FORM_ITEM_VALIDATION } from '@/shared/constants';
import { SentryService } from '@/shared/services/SentryService';
import { V3Service } from '@/shared/services/V3Service';
import {
  APIVersionService,
  FormItemValidation,
  ErrorTrackingService,
  GetFormItemValidationParams,
} from '@/shared/types';

export class ValidationService<E> {
  constructor(
    private apiService: APIVersionService<E>,
    private errorTrackingService: ErrorTrackingService
  ) {}

  private mapPathsToModelIds<T extends { id: string }>(err: ZodError, responseData: unknown) {
    if (!isObject(responseData)) return null;

    const modelIds = err.issues
      .map((issue) => {
        const [modelsKey, index] = issue.path;
        let failedModelId;
        if ('models' in responseData && modelsKey === 'models' && Number.isFinite(index))
          failedModelId = (responseData as Paginated<T>)[modelsKey][index as number].id;
        if ('data' in responseData && modelsKey === 'data' && Number.isFinite(index))
          failedModelId = (responseData as ResponseData<T[]>)[modelsKey][index as number].id;
        return failedModelId ? [failedModelId, JSON.stringify(issue.path)] : null;
      })
      .filter(Boolean);

    return modelIds.length ? Object.fromEntries(modelIds as []) : null;
  }

  isContainValidationError({ err, msg, param }: { err: Error; param: string; msg: string }) {
    return (
      err instanceof ValidationError &&
      Array.isArray(err.errors) &&
      err.errors.some((error) => error.param === param && error.msg === msg)
    );
  }

  validateResponseData({
    validationSchema,
    responseData,
    url,
  }: WithValidationSchema & { url: string; responseData: unknown }) {
    const domain = window.location.hostname.split('.')[0];
    if (!validationSchema) return;

    try {
      validationSchema.parse(responseData);
    } catch (err) {
      if (err instanceof ZodError) {
        const validationErrorName = this.getValidationErrorName(url);
        const validationErrorStringified = err.toString();
        if (process.env.NODE_ENV !== 'production' && domain === 'localhost') {
          console.error(validationErrorName, validationErrorStringified);
        } else {
          const failedModelIds = this.mapPathsToModelIds(err, responseData);
          const extraDataValue = { location: window.location, validationErrorName };
          this.errorTrackingService.captureExpandedException({
            exception: validationErrorStringified,
            extraData: {
              name: 'validationScheme error extra data',
              value: failedModelIds ? { ...extraDataValue, failedModelIds } : extraDataValue,
            },
            fingerprint: [window.location.href, validationErrorName],
          });
        }
      }
    }
  }

  private getValidationErrorName(url: string) {
    return `validationScheme error ${url}`;
  }

  validateForm<T>({ form, error, validations }: { form: FormInstance<T>; error: unknown; validations: unknown }) {
    if (!this.apiService.isError(error)) return;

    const errors = this.apiService.getErrors(error);
    const formFields = form.getFieldsValue();

    if (!formFields || typeof formFields !== 'object') return;

    errors.forEach((errorItem) => {
      const errorSourcePointer = this.apiService.getErrorPointer(errorItem);
      const errorDescription = this.apiService.getErrorDescription(errorItem, validations);

      if (!errorSourcePointer || !errorDescription) return;

      if (!(errorSourcePointer in formFields)) return;

      form.setFields([
        {
          name: errorSourcePointer,
          value: formFields[errorSourcePointer as keyof typeof formFields],
          errors: [errorDescription],
        },
      ]);
    });
  }

  getFormItemValidation({ error, validations, errorPointer }: GetFormItemValidationParams): FormItemValidation {
    if (!this.apiService.isError(error)) return EMPTY_FORM_ITEM_VALIDATION;

    const errors = this.apiService.getErrors(error);
    const formItemError = errors.find((errorItem) => {
      const errorSourcePointer = this.apiService.getErrorPointer(errorItem);
      return errorSourcePointer === errorPointer;
    });

    if (!formItemError) return EMPTY_FORM_ITEM_VALIDATION;

    const errorDescription = this.apiService.getErrorDescription(formItemError, validations);
    return errorDescription
      ? {
          validateStatus: 'error',
          help: errorDescription,
        }
      : EMPTY_FORM_ITEM_VALIDATION;
  }
}

export const validationService = new ValidationService(new V3Service(), new SentryService());
