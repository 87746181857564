import { z } from 'zod';

const paginatedSchema = <T>(responseData: z.ZodType<T>) =>
  z.object({
    currentPage: z.number(),
    totalItems: z.number(),
    totalPages: z.number(),
    models: z.array(responseData),
  });

export default paginatedSchema;
