export const BACKEND_HOST = process.env?.REACT_APP_BACKEND_HOST;
export const BACKEND_SUPPORT_HOST = process.env?.REACT_APP_BACKEND_SUPPORT_HOST;
export const LAST_COMMIT_HASH_SERVER_HOST = process.env?.REACT_APP_LAST_HASH_SERVER_HOST;
export const BUILD_COMMIT_HASH = process.env?.REACT_APP_BUILD_HASH;
export const IS_LOCAL_BUILD = process.env?.REACT_APP_IS_LOCAL_BUILD;
export const WATERFALL_SOCKET_ENDPOINT = process.env?.REACT_APP_WATERFALL_SOCKET_ENDPOINT;
export const ELEVATION_SERVER_HOST = process.env?.REACT_APP_ELEVATION_SERVER_HOST;
export const BASE_URL = `${BACKEND_HOST}/api/v1`;
export const BASE_PREFIX = '/api/v1';
export const V2_PREFIX = '/api/v2';
export const V3_PREFIX = '/api/v3';
