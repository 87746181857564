export const SOURCES_QUERY_KEY = 'SOURCES';

export const SOURCE_BY_ID_QUERY_KEY = 'SOURCE_BY_ID';

export const FOX_SETTINGS_QUERY_KEY = 'FOX_SETTINGS';

export const FOX_KEYS_QUERY_KEY = 'FOX_KEYS';

export const FILES_QUERY_KEY = 'FILES';

export const FOX_FREQUENCIES_QUERY_KEY = 'FOX_FREQUENCIES';

export const ALL_FOX_FREQUENCIES_QUERY_KEY = 'ALL_FOX_FREQUENCIES';

export const FOX_WATERFALL_AUTH_QUERY_KEY = 'FOX_WATERFALL_AUTH';

export const IS_LAST_BUILD_QUERY_KEY = 'IS_LAST_BUILD';

export const UNITS_QUERY_KEY = 'UNITS';

export const UNIT_DETAILS_QUERY_KEY = 'UNIT_DETAILS';

export const FACTION_LEVELS_QUERY_KEY = 'FACTION_LEVELS';

export const FACTION_TYPES_QUERY_KEY = 'FACTION_TYPES';

export const UNIT_FREE_USERS_QUERY_KEY = 'UNIT_FREE_USERS';

export const UNIT_USERS_QUERY_KEY = 'UNIT_USERS';

export const UNIT_SOURCES_QUERY_KEY = 'UNIT_SOURCES';

export const FILE_FILTERS_QUERY_KEY = 'FILE_FILTERS';

export const FOX_FREQUENCIES_FILTERS_QUERY_KEY = 'FOX_FREQUENCIES_FILTERS';

export const FILTER_FREQUENCIES_QUERY_KEY = 'FILTER_FREQUENCIES';

export const FILTER_SOURCES_QUERY_KEY = 'FILTER_SOURCES';

export const INTERCEPTIONS_MAP_QUERY_KEY = 'INTERCEPTIONS_MAP';
