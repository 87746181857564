import axios, { AxiosError } from 'axios';
import { BACKEND_HOST } from '@/shared/config';
import { handleRequest, handleResponse, handleErrors } from './interceptors';

export const axiosApiInstance = axios.create({
  baseURL: BACKEND_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosApiInstance.interceptors.request.use(handleRequest);

axiosApiInstance.interceptors.response.use(handleResponse, (error: AxiosError) =>
  handleErrors(error, axiosApiInstance).catch((err) => {
    throw AxiosError.from(err);
  })
);
