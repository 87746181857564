import { z } from 'zod';
import { RoleName } from '@/shared/constants';

export const roleNameSchema = z.nativeEnum(RoleName);

const userGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  full_name: z.array(z.string()),
});

export const baseUserSchema = z.object({
  id: z.number(),
  phone: z.string(),
  full_name: z.string().nullable(),
  role: roleNameSchema,
  is_active: z.boolean(),
  is_admin: z.boolean(),
  unit_id: z.string().nullish(),
  unit_name: z.string().nullish(),
});

export const userSchema = baseUserSchema.merge(
  z.object({
    group: userGroupSchema,
    created_at: z.number(),
    full_name: z.string(),
  })
);

export const newUserSchema = baseUserSchema.merge(
  z.object({
    group: userGroupSchema.nullable(),
    created_at: z.number(),
  })
);

export const userRoleSchema = z.object({
  id: z.string(),
  name: roleNameSchema,
});
