export const SOURCES_FILTERS_STORAGE_KEY = 'sources-filters';

export enum SourceTab {
  Scanning = 'scanning',
  Records = 'records',
  Keys = 'keys',
  Settings = 'settings',
}

export enum SourceQueryParam {
  Search = 's_s',
  Type = 's_t',
  Online = 's_on',
  Offline = 's_off',
}

export enum FoxScanningQueryParam {
  Active = 'act',
  Deactivated = 'deact',
  Digital = 'f_s_d',
  Analog = 'f_s_a',
  EncryptionType = 'f_s_e_t',
  Sort = 'sort',
  Search = 'f_s_s',
}

export enum FoxRecordsQueryParam {
  EncryptionType = 'f_r_e_t',
  Frequency = 'f_r_f',
  SourceAddress = 'f_r_s_a',
  GroupAddress = 'f_r_g_a',
  DateRange = 'f_r_d_r',
  DigitalSignal = 'f_r_d_s',
  AnalogSignal = 'f_r_a_s',
  Decoded = 'f_r_d',
  NotDecoded = 'f_r_n_d',
  Search = 'f_r_s',
}
