import { AUTH_DATA_LOCAL_STORAGE_KEY, LOGIN_PATH, PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY } from '@/shared/constants';

type Options = {
  shouldSaveLastRoute?: boolean;
};

const logout = ({ shouldSaveLastRoute = false }: Options = {}) => {
  if (shouldSaveLastRoute) {
    localStorage.setItem(PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY, `${window.location.pathname}${window.location.search}`);
  }
  localStorage.removeItem(AUTH_DATA_LOCAL_STORAGE_KEY);
  window.location.replace(LOGIN_PATH);
  window.location.reload();
};

export default logout;
