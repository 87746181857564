import isJsonString from '@/shared/utils/isJsonString';

const getLocalStorageValue =
  <T>(key: string) =>
  (): T | null => {
    const value: string | null = localStorage.getItem(key);

    return isJsonString(value) ? (JSON.parse(value as string) as T) : null;
  };

export default getLocalStorageValue;
