import { z } from 'zod';

export const codeSchema = z.object({
  id: z.string(),
  name: z.string(),
  value: z.string().nullable(),
  note: z.string().nullable(),
  frequency_id: z.string(),
  last_active_date: z.number().nullable(),
});
