import { z } from 'zod';
import { accessGroupSchema } from './accessGroupSchemas';
import { baseCategorySchema } from './categorySchemas';
import { factionSchema } from './factionSchemas';
import { locationSchema } from './locationSchemas';
import { baseSourceSchema } from './sourceSchemas';

const filtersCategorySchema = z
  .object({
    children: z.array(baseCategorySchema),
  })
  .merge(baseCategorySchema);

export const filtersSourceSchema = baseSourceSchema.pick({ id: true, name: true, type: true });

export const baseFilters = z.object({
  locations: z.array(locationSchema),
  factions: z.array(factionSchema),
  categories: z.array(filtersCategorySchema),
  groups: z.array(accessGroupSchema),
});

export const networkFiltersSchema = baseFilters;

export const transcriptionFiltersSchema = baseFilters;
