const hasTouchScreen = () => {
  const { userAgent } = navigator;
  let isTouchScreen: boolean;

  if ('maxTouchPoints' in navigator) {
    isTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    // @ts-ignore
    isTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mediaQuery = matchMedia?.('(pointer:coarse)');
    if (mediaQuery?.media === '(pointer:coarse)') {
      isTouchScreen = !!mediaQuery.matches;
    } else if ('orientation' in window) {
      isTouchScreen = true;
    } else {
      isTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent) ||
        /\b(Mobi|Mobile|Tablet)\b/i.test(userAgent);
    }
  }
  return isTouchScreen;
};

export default hasTouchScreen;
