import { ViewportType } from '@/shared/types';

interface Params {
  mobileMaxWidth: number;
  tabletMaxWidth: number;
  width: number;
}

const getViewportType = ({ mobileMaxWidth, tabletMaxWidth, width }: Params): ViewportType => {
  if (width <= mobileMaxWidth) return 'mobile';
  if (width <= tabletMaxWidth) return 'tablet';
  return 'desktop';
};

export default getViewportType;
