import { z } from 'zod';

export const baseFactionSchema = z.object({
  id: z.string(),
  name: z.string(),
  short_name: z.string(),
  full_name: z.string(),
  full_short_name: z.string(),
});

const singleFactionSchema = baseFactionSchema.merge(
  z.object({
    name_ru: z.string().nullish(),
    short_name_ru: z.string().nullish(),
    country: z.string().optional(),
    level_id: z.number().nullish(),
    type_id: z.number().nullish(),
    military_base: z.string().nullish(),
    state_registry_number: z.string().nullish(),
    parent_id: z.string().nullable(),
  })
);

type Faction = z.infer<typeof singleFactionSchema> & {
  children?: Faction[];
};

export const factionSchema: z.ZodType<Faction> = singleFactionSchema.merge(
  z.object({
    children: z.lazy(() => z.array(factionSchema)).optional(),
  })
);

export const factionDetailsSchema = singleFactionSchema.and(
  z.object({
    base_location: z.string().nullish(),
    info: z.string().nullish(),
  })
);
