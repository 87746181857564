import { z } from 'zod';
import { latLngCoordinatesSchema } from './coordinatesSchemas';
import { locationTypeSchema } from './locationSchemas';

const baseAccessGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  full_name: z.array(z.string()),
  state: z
    .object({
      id: z.string().nullish(),
      type: locationTypeSchema.nullish(),
      name: z.string().nullish(),
      district: z.string().nullish(),
      state: z.string().nullish(),
      coordinates: latLngCoordinatesSchema.nullish(),
    })
    .nullish(),
});

type AccessGroup = z.infer<typeof baseAccessGroupSchema> & {
  children?: AccessGroup[];
};

export const accessGroupSchema: z.ZodType<AccessGroup> = baseAccessGroupSchema.and(
  z.object({
    children: z.lazy(() => accessGroupSchema.array()).optional(),
  })
);
