import classNames from 'classnames';
import styles from './styles.module.css';

interface Params {
  selected: boolean;
  isDragging?: boolean;
  isDisabled?: boolean;
  withBorderBottom?: boolean;
}

export const getListItemClassNames = ({
  selected,
  isDragging = false,
  isDisabled = false,
  withBorderBottom = true,
}: Params) => {
  return classNames(styles.listItem, {
    [styles.selected]: selected,
    [styles.dragging]: isDragging,
    [styles.disabled]: isDisabled,
    [styles.border]: withBorderBottom,
  });
};
