import { z } from 'zod';
import { latLngCoordinatesSchema } from './coordinatesSchemas';

export const locationTypeSchema = z.enum([
  'SETTLEMENT',
  'VILLAGE',
  'URBAN',
  'CITY',
  'CAPITAL_CITY',
  'STATE',
  'DISTRICT',
  'PLACE',
]);

export const locationSchema = z.object({
  id: z.string(),
  type: locationTypeSchema,
  name: z.string(),
  district: z.string().nullable(),
  state: z.string().nullable(),
  country: z.string().optional(),
  coordinates: latLngCoordinatesSchema.nullable().optional(),
});
