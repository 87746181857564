import { z } from 'zod';
import { baseFactionSchema } from './factionSchemas';

export const callSignSchema = z.object({
  id: z.string(),
  name: z.string(),
  aliases: z.array(z.string()),
  factions: z.array(baseFactionSchema),
  position: z.string().nullable(),
  note: z.string().nullable(),
  created_at: z.number(),
  last_active_date: z.number().nullable(),
});
