export const ALL_TRANSCRIPTS_QUERY_PARAMS_KEY = 'transcriptQueryParams-all';

export enum TranscriptQueryParam {
  Order = 't_o',
  Search = 't_s',
  Location = 't_l',
  Faction = 't_f',
  Category = 't_c',
  Frequency = 't_fr',
  Source = 't_src',
  Group = 't_g',
  DateRange = 't_d',
}
