import axios from 'axios';

type ResponseError = {
  response: {
    error: string;
  };
};

const getIsAlreadyExistResponseError = (err: unknown) => {
  if (!axios.isAxiosError<ResponseError>(err) || !err.response) return false;

  const responseError = err.response.data;

  if (responseError.response?.error?.includes('already exists')) {
    return true;
  }
  return false;
};

export default getIsAlreadyExistResponseError;
