import { z } from 'zod';
import { RadioNetworkType } from '../constants';

const radioNetworkTypeSchema = z.nativeEnum(RadioNetworkType);

export const radioNetworkItemSchema = z.object({
  id: z.string(),
  value: z.string(),
  type: radioNetworkTypeSchema,
  first_active_at: z.number(),
  last_active_at: z.number(),
});

export const frequencySchema = radioNetworkItemSchema.merge(
  z.object({
    type: z.literal('frequency'),
    source_name: z.string().optional(),
  })
);

export const networkSchema = radioNetworkItemSchema.merge(
  z.object({
    type: z.literal('network'),
    frequencies: z.array(frequencySchema),
    description: z.string().nullish(),
  })
);

export const radioNetworkSchema = frequencySchema.or(networkSchema);
