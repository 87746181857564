export class ServerError extends Error {
  originalError: Error;

  name = 'ServerError';

  constructor(error: Error) {
    super('Server error');
    this.originalError = error;
  }
}
