import { RequestOptions } from '@/shared/api/types';

const optionsContainOnlySchema = (options?: RequestOptions) => {
  if (!options) return false;

  const optionsKeys = Object.keys(options);
  return optionsKeys.length === 1 && optionsKeys[0] === 'validationSchema';
};

export default optionsContainOnlySchema;
