import { InternalAxiosRequestConfig } from 'axios';
import { RequestService as RequestServiceI } from '@/shared/types';

export class RequestService implements RequestServiceI {
  getAbortedRequestConfig(config: InternalAxiosRequestConfig) {
    const controller = new AbortController();
    controller.abort();
    return {
      ...config,
      signal: controller.signal,
    };
  }

  getRequestConfigWithAccessTokenHeader(config: InternalAxiosRequestConfig, accessToken: string) {
    const { headers } = config;
    headers.set('X-access-token', accessToken);
    return config;
  }
}

export const requestService = new RequestService();
