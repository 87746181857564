import { AxiosError } from 'axios';
import isObject from 'lodash/isObject';
import { AuthErrorCode } from '@/shared/api/constants';
import { ResponseData } from '@/shared/api/types';
import { AUTH_DATA_LOCAL_STORAGE_KEY } from '@/shared/constants';
import { V3Service } from '@/shared/services/V3Service';
import { APIVersionService, AuthData, AuthService as AuthServiceI } from '@/shared/types';

export class AuthService<E> implements AuthServiceI {
  constructor(private apiService: APIVersionService<E>) {}

  authErrors = new Set(Object.values(AuthErrorCode)) as Set<string>;

  getAuthDataFromLocalStorage() {
    const authDataRaw = localStorage.getItem(AUTH_DATA_LOCAL_STORAGE_KEY);
    return authDataRaw ? (JSON.parse(authDataRaw) as ResponseData<AuthData>).data : null;
  }

  getAuthErrorCode(error: AxiosError) {
    const { response } = error;
    if (this.apiService.isError(error)) {
      return this.apiService.getAuthErrorDetail({ error, authErrors: this.authErrors });
    }
    if (isObject(response) && isObject(response.data)) {
      return 'code' in response.data && response.data.code;
    }
  }

  getAuthErrorMessage(error: AxiosError) {
    const { response, message } = error;

    if (isObject(response) && isObject(response.data)) {
      return 'error' in response.data ? (response.data.error as string) : message;
    }
  }
}

export const authService = new AuthService(new V3Service());
