const isJsonString = (value: unknown) => {
  if (typeof value !== 'string') {
    return false;
  }
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

export default isJsonString;
