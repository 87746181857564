import { InternalAxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import { ServerError } from '../errors';
import { authService, errorHandlingService, requestService } from '../services';
import { logout } from '../utils';

export const handleRequest = (config: InternalAxiosRequestConfig) => {
  const authData = authService.getAuthDataFromLocalStorage();
  if (!authData) {
    logout({ shouldSaveLastRoute: true });
    return requestService.getAbortedRequestConfig(config);
  }
  return requestService.getRequestConfigWithAccessTokenHeader(config, authData.accessToken);
};

export const handleResponse = (response: AxiosResponse) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response.status === 204 && !response.data ? {} : response.data;
};

export const handleErrors = async (err: AxiosError, instance: AxiosInstance) => {
  let error = err;

  if (error.response?.data instanceof Blob) {
    error = await errorHandlingService.getParsedBlobError(err);
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error.response?.status && error.response.status >= 500 && error.response.status <= 599) {
    return Promise.reject(new ServerError(error));
  }

  const authError = await errorHandlingService.getAuthError({ error, instance });

  if (!authError) {
    return Promise.reject(errorHandlingService.getError(error));
  }

  if (authError instanceof Error) {
    return Promise.reject(authError);
  }

  return authError;
};
